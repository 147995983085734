import React from "react";

const ComingSoon = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold text-gray-700 mb-4">Coming Soon</h1>
      <p className="text-gray-500 mb-6">
        We're working hard to bring this feature to you. Stay tuned!
      </p>
    </div>
  );
};

export default ComingSoon;