// src/App.js

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer.jsx";
import Header from "./components/Header.jsx";
import LandingPage from "./pages/Landingpage/LandingPage";
import Jobs from "./pages/jobs/Jobs.jsx";
import ISearch from "./pages/iSearch/index.jsx";
import AIPS from "./pages/aips/aips.jsx";
import AboutUs from "./pages/about/AboutUs.jsx";
import Investor from "./pages/investor/investor.jsx";
import ContactUs from "./pages/contact/ContactUs.jsx";
import LoginPage from "./pages/login/login.jsx";
import Register from "./pages/register/register.jsx";
import Profile from "./pages/userprofile/index.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";

function AppContent() {
  const location = useLocation();
  
  // Check if Header and Footer should be displayed
  const showHeaderAndFooter =
    location.pathname !== "/login" && location.pathname !== "/register";

  return (
    <div>
      {/* Display Header only on non-login/register pages */}
      {showHeaderAndFooter && <Header />}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />{" "}
        <Route path="/jobs" element={<Jobs />} />{" "}
        <Route path="/iSearch" element={<ISearch />} />{" "}
        <Route path="/AIPS" element={<AIPS />} />{" "}
        <Route path="/AboutUs" element={<AboutUs />} />{" "}
        <Route path="/Investor" element={<Investor />} />{" "}
        <Route path="/ContactUs" element={<ContactUs />} />{" "}
        <Route path="/login" element={<LoginPage />} />{" "}
        <Route path="/register" element={<Register />} />{" "}
        <Route path="/avatar" element={<ComingSoon />} />{" "}
        <Route path="/updatePassword" element={<ComingSoon />} />{" "}
        
        {/* Catch-all Route for 404 */}
        <Route path="*" element={<PageNotFound />} />{" "}
        
        {/* Protected Routes */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
      </Routes>
      {/* Display Footer only on non-login/register pages */}
      {showHeaderAndFooter && <Footer />}
    </div>
  );
}

function App() {
  return <AppContent />;
}

export default App;