// src/index.js

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import react-router-dom
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Import your components/pages
import CCAdmin from "./pages/admin/ccAdmin"; // Admin Page
import ProtectedRoute from "./components/ProtectedRoute"; // ProtectedRoute Component

// Function to check if admin is authenticated
const isAdminAuthenticated = () => {
  const adminToken = localStorage.getItem("adminToken");
  return !!adminToken; // Return true if adminToken exists
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/*" element={<App />} />
        
        {/* Private Admin Route */}
        <Route
          path="/CCAdmin"
          element={
            <ProtectedRoute isAuthenticated={isAdminAuthenticated()}>
              <CCAdmin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();