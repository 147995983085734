import React, { useEffect, useState } from "react";
import LeftJobList from "../jobs/LeftJobList";
import { useNavigate } from "react-router-dom";

const SavedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSavedJobs = async () => {
      const token = localStorage.getItem("userToken");

      if (!token) {
        setError("Your session has expired. Please log in to view saved jobs.");
        navigate("/login");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/savedJobs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch saved jobs.");
        }

        const data = await response.json();
        // console.log("Fetched Saved Jobs:", data);
        setJobs(data.bookmarkedJobs); // Update with saved jobs
      } catch (error) {
        console.error("[ERROR] Error fetching saved jobs:", error);
        setError("Unable to load saved jobs. Please try again later.");
      }
    };

    fetchSavedJobs();
  }, [navigate]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-xl font-bold text-gray-700 mb-4">Error</h1>
          <p className="text-sm text-gray-500 mb-6">{error}</p>
          <button
            onClick={() => navigate("/login")}
            className="px-4 py-2 bg-[#049c64] text-white rounded-md text-sm hover:bg-emerald-500 transition-colors"
          >
            Go to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-[5%]">
      {/* Main Content */}
      <div className="flex flex-col sm:flex-row w-full gap-4">
        <div className="w-full sm:w-full">
          <LeftJobList jobs={jobs} source="savedJobs" />
        </div>
      </div>
    </div>
  );
};

export default SavedJobs;