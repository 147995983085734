import React from "react";

const investor = () => {
  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
      {/* Hero Section */}
      <section className="bg-emerald-100 py-16 flex items-center justify-center relative">
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            Invest in Us
          </h1>
        </div>
      </section>

      {/* Why Invest in Careers Central? */}
      <section className="py-12 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Why Invest in Careers Central?</h2>
          <div className="space-y-3 text-gray-700 text-justify">
            <p>
              Careers Central offers a unique opportunity to be part of a platform transforming the job search landscape. Here’s why you should consider partnering with us:
            </p>
            </div>
            <ul className="list-disc pl-10 py-3 space-y-3 text-gray-700 text-justify">
            <li>
              <strong>Shared Success:</strong> We are offering the investment opportunity to the public, entitling investors to <strong>20% of revenue</strong> generated by 
              Careers Central. The remaining 80% of the revenue is strategically allocated to business development, operations, strategic initiatives, future trading opportunities, 
              and the foundation group, ensuring the platform’s continuous growth, innovation, and long-term sustainability. This structure ensures that every contribution directly 
              supports the platform's growth while providing a transparent and rewarding opportunity for all participants.
            </li>
            <li>
              <strong>Transparent Ownership:</strong> All investments are securely managed through a trusted public distributed ledger, ensuring clear ownership records and precise 
              revenue sharing. This system guarantees transparency and reliability for all stakeholders.
            </li>
            <li>
              <strong>Low Investment Threshold:</strong> Start your investment journey with as little as <strong>INR 10,000</strong>, making it accessible 
              for everyone to join our journey. An investment of INR 10 Lakh will entitle you to 1% of our revenue margin.
            </li>
            <li>
              <strong>Monthly Revenue Distribution:</strong> Distribution amount is calculated after operational expenses and distributed monthly, 
              proportionate to your holdings in the investment pool.
            </li>
            <li>
              <strong>Future Trading Opportunities:</strong> By late 2025 or early 2026, we plan to introduce trading options for investors providing liquidity
              and flexibility for investors.
            </li>
            <li>
              <strong>Sustainable Growth Model:</strong> Our subscription plans and growing user base ensure a reliable and scalable revenue stream.
            </li>
          </ul>
        </div>
      </section>

      {/* How Will Your Investment Be Used? */}
      <section className="py-12 bg-gray-50 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">How Will Your Investment Be Used?</h2>
          <p className="text-gray-700">
              Every rupee invested goes towards:
            </p>
          <ul className="list-disc pl-10 py-3 space-y-3 text-gray-700">
            <li>
              Establishing a seamless platform for job seekers and companies.
            </li>
            <li>
              Expanding operations to ensure scalability and success.
            </li>
            <li>
              Utilizing advanced technologies for better user experience.
            </li>
          </ul>
        </div>
      </section>

      {/* What’s Ahead in 2025? */}
      <section className="py-12 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">What’s Ahead in 2025?</h2>
          <div className="py-3 space-y-3 text-gray-700">
            <p>We’re poised for growth in 2025, focusing on key initiatives 
              that drive success for Careers Central and its investors:</p>
          </div>
          <ul className="list-disc pl-10 space-y-3 text-gray-700 text-justify">
            <li>
              <strong>Building Strategic Partnerships:</strong> In Q1, we’ll 
              establish relationships with institutions and companies to create
              a robust job aggregation database.
            </li>
            <li>
              <strong>Expanding Our Reach:</strong> By Q2, we'll launch targeted 
              marketing campaigns and introduce a freemium model to attract job seekers,
              laying the groundwork for widespread adoption.
            </li>
            <li>
              <strong>Monetizing Through Subscriptions:</strong> In Q3, we’ll launch 
              the Pro-Lite subscription plan, generating sustainable revenue streams.
            </li>
            <li>
              <strong>Delivering Value to Investors:</strong> By mid Q4, we’ll start 
              distributing revenue, ensuring our investors see tangible returns on
              their contributions.
            </li>
          </ul>
        </div>
      </section>

      {/* Why Careers Central is the Right Choice for You */}
      <section className="py-12 bg-gray-50 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Why Careers Central is the Right Choice for You?</h2>
          <ul className="list-disc pl-10 space-y-3 text-gray-700">
            <li>
              <strong>Reliable Revenue Model:</strong> Sustainable growth through subscription plans and a strong user base.
            </li>
            <li>
              <strong>Scalable Growth Potential:</strong> Focused partnerships, marketing, and innovation ensure steady progress.
            </li>
            <li>
              <strong>Long-Term Vision:</strong> Revolutionizing the job search landscape with a people-first approach.
            </li>
          </ul>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-12 text-center bg-emerald-100">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold text-green-600 mb-4">
            Join Us Today
          </h2>
          <p className="text-gray-700 text-lg mb-6">
            Careers Central is your opportunity to invest in a revolutionary 
            platform that bridges the gap between talent and opportunity. 
            Your investment fuels innovation, operational excellence, and a 
            brighter future for job seekers in India.
          </p>
          <p>
            Take the first step — Invest today and be part of our success story! To learn more or start investing, please visit our 
            <a href="/contact" className="text-green-600 font-bold hover:underline"> Contact Us </a> page.
          </p>
        </div>
      </section>
    </div>
  );
};

export default investor;