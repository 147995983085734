// src/pages/about/AboutUs.jsx

import React from "react";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/jobs");
  };

  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
      {/* Hero Section */}
      <section className="bg-emerald-100 py-16 flex items-center justify-center relative">
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            About Careers Central
          </h1>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-12 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
          <p className="text-gray-700 text-justify">
            At <span className="font-bold">Careers Central</span>, our mission 
            is to bridge the gap between talent and opportunity. We streamline 
            the job search process by consolidating job ads from multiple companies
            into a single, user-friendly platform. Whether you’re an experienced
            professional or taking your first steps in your career, Careers Central
            is here to help you discover the right opportunities with confidence
            and ease.
          </p>
        </div>
      </section>

      {/* What We Offer Section */}
      <section className="py-12 bg-gray-50 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">What We Offer</h2>
          <ul className="list-disc pl-10 space-y-3 text-gray-700">
          <li>
              <strong>Job Aggregation:</strong> Discover job opportunities 
              aggregated from our partnered companies, presented in an easy-to-read 
              light mode for user convenience.
            </li>
            <li>
              <strong>Unified Job Search:</strong> Search for roles across
              leading companies in seconds with our intuitive{" "}
              <span className="font-bold">iSearch</span> feature.
            </li>
            <li>
              <strong>Real-Time Updates:</strong> Access the most up-to-date
              job listings directly from company websites.
            </li>
          </ul>
        </div>
      </section>

      {/* Meet the Founder Section */}
      <section className="py-12 px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start gap-8">
          {/* Founder Content */}
          <div className="w-full md:w-2/3">
            <h2 className="text-2xl font-bold mb-4">Meet Vimal, the Visionary Behind Careers Central</h2>
            <p className="text-gray-700 mt-2 text-justify">
              Vimal brings over 15 years of professional experience,
              including a wealth of expertise in Staffing, Global Mobility, 
              Compliance and Strategic Planning. As the visionary behind{" "}
              <span className="font-bold">Careers Central</span>, Vimal is
              dedicated to bridging the gap between job seekers and employers
              through innovative approach and industry insights.
            </p>
            <p className="text-gray-700 mt-4 text-justify">
              With a career spanning diverse industries and geographies, Vimal
              has successfully managed teams, streamlined processes, and
              introduced cost-saving initiatives. His vision for Careers Central
              is rooted in his passion for creating an inclusive and efficient
              job market.
            </p>
            </div>

            {/* Founder Image */}
            <div className="w-full md:w-1/3 flex justify-center">
              <img
                src="https://via.placeholder.com/300" // Replace with the founder's image
                alt="Founder"
                className="rounded-lg shadow-md"
              />
            </div>
          </div>
        </section>

        {/* Co-Founder Section */}
        <section className="py-12 px-6">
        <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start gap-8">
          {/* Co-Founder Content */}
          <div className="w-full md:w-2/3">
            <h2 className="text-2xl font-bold mb-4">Meet Surya, the Driving Force Behind Operations at Careers Central</h2>
                <p className="text-gray-700 mt-2 text-justify">
                  With a strong foundation in food technology and quality assurance, 
                  Surya Prabha brings a wealth of experience in managing complex processes 
                  with precision and dedication. Her academic and professional journey is 
                  marked by a commitment to excellence, having contributed to projects that 
                  enhanced product longevity and optimized resource utilization. 
                </p>
                <p className="text-gray-700 mt-4 text-justify">
                  At <span className="font-bold">Careers Central</span>, Surya oversees the 
                  day-to-day operations, ensuring seamless execution and fostering a culture 
                  of continuous improvement.
                </p>
                </div>

              {/* Co-Founder Image */}
              <div className="w-full md:w-1/3 flex justify-center">
              <img
                src="https://via.placeholder.com/300" // Replace with the co-founder's image
                alt="Co-Founder"
                className="rounded-lg shadow-md"
              />
          </div>
        </div>
      </section>

      {/* Acknowledgment Section */}
      <section className="py-12 bg-gray-50 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Acknowledgments</h2>
{/**}
          <p className="text-gray-700 text-justify">
            This platform is a testament to the unwavering support and inspiration
            of those who have shaped Vimal’s journey, both personally and professionally.
            He extends heartfelt gratitude to his parents, spouse, siblings, with special
            mention to his uncle, Prof. C. Kulothungan, for his unwavering support and for 
            shaping his journey from the very beginning.
          </p>
          <p className="text-gray-700 mt-4 text-justify">
            In personal life, he acknowledges the profound influence of his friends, 
            Mr. Naveen Kumar, Dr. Deepak Rajendran, Dr. Rajendran & Family for making home away 
            from home. In his professional journey, he is deeply grateful to Mrs. Geetha Ganesh, 
            Mrs. Sarah Mathew, Mr. Senthil Pandian, and Mr. Premkumar Natarajan for their mentorship 
            and impactful contributions.
          </p>
 {/*chang to shaping his */}          
          <p className="text-gray-700 mt-4 text-justify">
            This website is dedicated to all who have played a role in shaping the vision, values, 
            and unwavering commitment to creating opportunities for others.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-12 px-6">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Why Choose Careers Central?</h2>
          <ul className="list-disc ml-6 space-y-3 text-gray-700">
            <li>
              <strong>Personalized Experience:</strong> Tailored job
              recommendations based on your unique aspirations.
            </li>
            <li>
              <strong>Comprehensive Coverage:</strong> Access job postings from
              across industries, ensuring you never miss an opportunity.
            </li>
            <li>
              <strong>Tailored Job Matches:</strong> Utilize our advanced iSearch 
              methodology to find roles perfectly aligned with your preferences, skills, 
              and career aspirations.
            </li>
            <li>
              <strong>Aggregated Job Insights:</strong> Access a consolidated view of job 
              opportunities from multiple industries and companies, saving you time and effort 
              in your job search.
            </li>
          </ul>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-12 text-center bg-emerald-100">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold text-green-600 mb-4">
            Ready to Unlock Your Career Potential?
          </h2>
          <p className="text-gray-700 text-lg mb-6">
            Join Careers Central today and discover opportunities that align
            with your skills and aspirations.
          </p>
          <button 
          onClick={handleButtonClick}
          className="bg-[#049c64] hover:bg-emerald-500 text-white px-8 py-3 rounded-full text-lg transition-colors"
          >
            Get Started
          </button>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;