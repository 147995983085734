// src/pages/jobs/Jobs.jsx

import { ChevronDown, MapPin, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import Filter from "../../Images/Slider.png";
import LeftJobList from "./LeftJobList";

const Jobs = () => {
  // const [selectedJob, setSelectedJob] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [jobs, setJobs] = useState([]); // All jobs fetched from the backend
  const [filteredJobs, setFilteredJobs] = useState([]); // Filtered jobs based on search
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const jobsPerPage = 30; // Number of jobs per page
  const [searchKeyword, setSearchKeyword] = useState(""); // Input for keyword search
  const [searchCCID] = useState(""); // Input for CCID search
  const [searchLocation, setSearchLocation] = useState(""); // Input for Location search
  const [fetchError, setFetchError] = useState(false);

  // Handle responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // 1024px is the lg breakpoint
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch jobs from the backend
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jobs`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Fetched jobs:", data);

        const transformedJobs = data.map((job) => ({
          id: job.Job_ID || "N/A",
          ccid: job.CCID || "N/A",
          title: job.Job_Title || "N/A",
          company: job.Company || "N/A",
          experience: `${job.Min_Exp || "NS"} - ${job.Max_Exp || "NS"} Yrs`,
          location: job.Location || "N/A",
          minSalary: job.Min_Salary ? Number(job.Min_Salary) : "NS",
          maxSalary: job.Max_Salary ? Number(job.Max_Salary) : "NS",
          department: job.Function || "N/A",
          postedOn: job.Posted_On ? new Date(job.Posted_On).toLocaleDateString() : "N/A",
          ccUpdateDate: job.CC_Updated_On
            ? new Date(job.CC_Updated_On).toLocaleDateString()
            : "N/A",
          keyword: job.Keywords || "No Keywords Available", // Use for display in card
          searchParams: job.Search_Params || "No Search Data Available", // Use for search functionality
          url: job.URL || "URL Not Found",
        }));

        setJobs(transformedJobs); // Save all jobs
        setFilteredJobs(transformedJobs); // Initialize filtered jobs with all jobs
        setFetchError(false); // Reset error
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setFetchError(true); // Set error state
      }
    };

    fetchJobs();
  }, []); // Runs only once when the component mounts

  // Calculate total pages
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  // Get jobs for the current page
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle search functionality for Keyword, CCID, and Location
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      let filtered = jobs;

      // Reset to all jobs when no search criteria are provided
      if (!searchKeyword.trim() && !searchCCID.trim() && !searchLocation.trim()) {
        setFilteredJobs(jobs);
        return; // Exit early
      }
      
      // Filter by multiple keywords
      if (searchKeyword.trim()) {
        const keywords = searchKeyword
          .split(",") // Split by commas
          .map((keyword) => keyword.trim().toLowerCase()) // Trim and normalize
          .filter((keyword) => keyword.length > 0); // Remove empty strings

        filtered = filtered.filter((job) =>
          keywords.some((keyword) =>
            job.searchParams?.toLowerCase().includes(keyword)
          )
        );
      }

      // Filter by CCID
      if (searchCCID.trim()) {
        filtered = filtered.filter((job) =>
          job.ccid?.toLowerCase().includes(searchCCID.toLowerCase())
        );
      }

      // Filter by location (multiple cities) including partial matches
      if (searchLocation.trim()) {
        const cities = searchLocation
        .split(",") // Split by comma
        .map((city) => city.trim().toLowerCase()) // Trim and normalize
        .filter((city) => city.length > 0); // Remove empty strings

        filtered = filtered.filter((job) => {
          const jobLocation = job.location?.toLowerCase(); // Normalize job location
          return cities.some((city) => jobLocation.includes(city)); // Partial match anywhere
        });
      }

        setFilteredJobs(filtered);
        // setSelectedJob(filtered[0]); // Update selected job to the first result
        // setSelectedJob(filtered[0] || null); // Update selected job to the first result
    }, 300); // Debounce for 300ms

    return () => clearTimeout(delayDebounce); // Cleanup debounce
  }, [searchKeyword, searchCCID, searchLocation, jobs]);

  return (
    <div className="max-w-full mt-[12%] lg:mt-[6%] xl:mt-[4%] sm:mt-[8%]">
      <hr className="relative left-1/2 right-1/2 -mx-[49vw] w-[98vw] border-t-2 border-gray-200 mt-4" />
      
      {/* Display fetch error */}
      {fetchError && (
          <div className="text-red-500 text-center">Failed to load jobs. Please try again later.</div>
      )}

      {/* Search Section */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-2 py-3 px-[5%] mt-1">
        {/* Search Keywords */}
        <div className="sm:col-span-10 relative">
          <div className="absolute inset-y-0 left-3 flex items-center">
            <Search className="h-5 w-5 lg:h-4 lg:w-4 lg:-mt-1 text-[#049c64]" />
          </div>
          <input
            type="text"
            placeholder="Search by Keywords, Skills, Job Title, Role..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="lg:text-xs w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium"
          />
        </div>

        {/* Search By Location */}
        <div className="sm:col-span-2 relative">
          <div className="absolute inset-y-0 left-3 flex items-center">
            <MapPin className="h-5 w-5 lg:h-4 lg:w-4 lg:-mt-1 text-[#049c64]" />
          </div>
          <input
            type="text"
            placeholder="Location"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            className="lg:text-xs w-full pl-10 pr-3 py-2 bg-emerald-100 border-0 rounded-md focus:ring-2 focus:ring-emerald-500 placeholder-[#049c64] font-medium"
          />
        </div>
      </div>

      <hr className="relative left-1/2 right-1/2 -mx-[49vw] w-[98vw] border-t-2 border-gray-200 mt-1" />

      {/* Filters Section */}
      <div className="grid grid-cols-1 sm:grid-cols-7 gap-4 mb-6 items-center w-full mt-3 px-[5%] overflow-x-auto">
        <button className="flex items-center justify-center gap-2 px-3 py-2 rounded-full border border-[#049c64] text-[#049c64] font-medium text-xs whitespace-nowrap">
          <img src={Filter} alt="Filter" className="h-4 w-4" />
          Filter
        </button>

        {["Industry", "Function", "Exp.Min", "Exp.Max", "Sort By"].map(
          (filter) => (
            <button
              key={filter}
              className="flex items-center justify-center gap-2 px-3 py-1 bg-fff9f7 border border-gray-300 border-solid rounded-full hover:bg-gray-50 md:text-xs xl:text-base lg:text-xxs whitespace-nowrap"
            >
              {filter}
              <ChevronDown className="h-4 w-4" />
            </button>
          )
        )}

        <div className="text-gray-700 font-medium text-right md:text-sm xl:text-base whitespace-nowrap">
          {filteredJobs.length} Jobs Found
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row w-full gap-4 px-[5%] min-h-screen">
        {/* Left Panel */}
        <div
          className={`${
            isMobile ? "w-full" : "w-full"
          } flex-grow h-full overflow-hidden`}
        >
          <div className="sticky top-0 h-full">
            {/* Pass filteredJobs to LeftJobList */}
            <LeftJobList jobs={paginatedJobs} />
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex flex-col sm:flex-row items-center justify-between mt-6 mb-2 px-[5%] gap-4">
        <span className="text-xs text-gray-600">
          {`${(currentPage - 1) * jobsPerPage + 1} - ${
            Math.min(currentPage * jobsPerPage, filteredJobs.length)
          } of ${filteredJobs.length}`}
        </span>
        <div className="flex gap-3 xs:gap-6 overflow-x-auto">
          {[...Array(totalPages).keys()].map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page + 1)}
              className={`w-5 h-5 flex items-center justify-center rounded ${
                page + 1 === currentPage
                  ? "bg-[#049c64] text-white"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              {page + 1}
            </button>
          ))}
          <button className="w-4 h-4 flex items-center justify-center rounded text-gray-600 hover:bg-gray-100">
            <ChevronDown className="h-4 w-4 rotate-270" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Jobs;