// src/components/ProtectedRoute.jsx

import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("userToken");
  // Highly Sensitive // console.log("[DEBUG] ProtectedRoute - Token fetched from localStorage:", token);

  if (!token) {
    // console.warn("[WARN] ProtectedRoute - No token found. Redirecting to /login.");
    return <Navigate to="/login" />;
  }

  // Optional: Decode token to check expiration


  // console.log("[INFO] ProtectedRoute - Token valid. Rendering children.");
  return children;

};

export default ProtectedRoute;