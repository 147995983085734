// src/pages/contact/ContactUs.jsx

import React, { useState } from "react";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    queryType: "Business Partnership",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact`,formData);

      setResponseMessage("Thank you for contacting us. Your query has been submitted successfully! We will get back to you within 1 business day.");
      setIsPopupVisible(true);

      // Clear form fields after submission
      setFormData({
        name: "",
        email: "",
        mobile: "",
        queryType: "Business Partnership",
        message: "",
      });
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      setResponseMessage(
        error.response?.data?.error || "Something went wrong. Please try again later."
      );
      setIsPopupVisible(true);
    }
  };
  
  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
    {/* Hero Section */}
    <section className="bg-emerald-100 py-16 flex items-center justify-center relative">
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            Submit Contact Form
          </h1>
        </div>
      </section>

      {/* Form Section */}
      <div className="container mx-auto max-w-2xl py-6 px-6">
        <form
          className="bg-white p-6 rounded-lg shadow-md"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label htmlFor="name" className="block font-bold mb-2">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block font-bold mb-2">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mobile" className="block font-bold mb-2">
              Your Mobile Number
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="queryType" className="block font-bold mb-2">
              Query Type
            </label>
            <select
              id="queryType"
              name="queryType"
              value={formData.queryType}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option>Business Partnership</option>
              <option>General Inquiry</option>
              <option>Investor</option>
              <option>Forgot Password</option>
              <option>Suggestions & Feedback</option>
              <option>Testimony</option>
              <option>Others</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block font-bold mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-emerald-500 text-white py-2 px-4 rounded-md hover:bg-emerald-600"
          >
            Submit
          </button>
        </form>

        {/* Popup for submission status */}
        {isPopupVisible && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md shadow-lg text-center">
              <p className="text-lg font-bold text-green-600">{responseMessage}</p>
              <button
                className="mt-4 bg-emerald-500 text-white py-2 px-4 rounded-md hover:bg-emerald-600"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
          )}
      </div>
    </div>
  );
};

export default ContactUs;