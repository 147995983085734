// src/api/refreshApi.js

import axios from "axios";

const refreshToken = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/refresh-token`, {
      withCredentials: true,
    });
    return response.data.accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error.message);
    return null;
  }
};

export default refreshToken;