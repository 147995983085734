// src/api/keywordApi.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Search Jobs (trigger the job search script)
export const searchJobs = async (keyword) => {
  try {
    const response = await axios.post(`${API_URL}/search-jobs`, { keyword });
    // console.log('Full Response:', response.data);
    // console.log('Search Results:', response.data.results);
    return response.data.results || []; // Default to an empty array if `results` is undefined
  } catch (error) {
    console.error('[ERROR] Error searching jobs:', error);
    throw error;
  }
};

// Increment Keyword Usage
export const incrementKeyword = async (keyword) => {
  try {
    // console.log('Incrementing keyword:', keyword); // Debugging
    const response = await axios.post(`${API_URL}/keywords`, { keyword });
    // console.log('Keyword increment response:', response.data); // Debugging
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error(
        '[ERROR] Server Error while incrementing keyword:',
        error.response.data || error.response.status
      );
    } else if (error.request) {
      // Request was made, but no response received
      console.error('[ERROR] No response from server:', error.request);
    } else {
      // Other errors
      console.error('[ERROR] Unexpected error during keyword increment:', error.message);
    }
    throw error; // Re-throw the error for further handling if necessary
  }
};

export const fetchFrequentlySearchedKeywords = async () => {
  try {
    const response = await axios.get(`${API_URL}/keywords`);
    // console.log('[DEBUG] Fetched frequently searched keywords:', response.data);
    return response.data;
  } catch (error) {
    console.error('[ERROR] Fetching keywords failed:', error.response?.data || error.message);
    return [];
  }
};