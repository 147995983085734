// src/components/FrequentlySearched.js

import React, { useEffect, useState } from 'react';
import { fetchFrequentlySearchedKeywords } from '../api/keywordApi';

const FrequentlySearched = ({ triggerSearch }) => {
  const [keywords, setKeywords] = useState([]);

  // Fetch initial frequently searched keywords using Axios
  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const fetchedKeywords = await fetchFrequentlySearchedKeywords();
          setKeywords(fetchedKeywords);
        } catch (error) {
          console.error('[ERROR] Fetching keywords failed:', error);
      }
    };
    fetchKeywords(); // Initial load
  }, []); // Reload whenever refreshTrigger changes

  return (
    <div className='p-0 h-full'>
      <h3 className='text-base font-bold text-gray-800 mb-2 text-center'>
        Frequently Searched Keywords
      </h3>
      <ul className='grid grid-cols-1 sm:grid-cols-1 list-none space-y-0.5'>
        {keywords && keywords.length > 0 ? (
          keywords.map((keyword) => (
            <li
              key={keyword.keyword}
              onClick={() => triggerSearch(keyword.keyword)} // Only pass the keyword (exclude frequency)
              className='flex justify-between items-center p-0.5 hover:bg-gray-200 rounded-lg-md cursor-pointer transition'
            >
              <span className='font-medium text-[10px] text-blue-600'>
                {keyword.keyword}
              </span>
              <span className='text-[10px] bg-green-500 text-white rounded-full px-2'>
                {keyword.frequency}
              </span>
            </li>
          ))
        ) : (
          <p className='text-center text-gray-500'>No keywords available</p>
        )}
      </ul>
    </div>
  );
};

export default FrequentlySearched;