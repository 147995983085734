// src/pages/userprofile/ProfileDetails.jsx

import { Info, MapPin, Award, AlertCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import visa from "../../Images/Visa.png";
// import Payment from "../../Images/paymnet.png";
import pen from "../../Images/pen.png";
import save from "../../Images/save.png";
// import Male from "../../Images/Male.png";
// import Female from "../../Images/Female.png";
import Unisex from "../../Images/Unisex.png";

const ProfileDetails = () => {
  const [profile, setProfile] = useState(null);
  const [editable, setEditable] = useState(false);
  const [editData, setEditData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch Profile Data Function
    const fetchProfile = async () => {
      const token = localStorage.getItem("userToken");
      // console.log("[DEBUG] ProfileDetails - Token sent in Authorization header:", token);

      if (!token) {
        setError("Your session has expired. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        if (!response.ok) {
          if (response.status === 401) {
            setError("Your session has expired. Please log in again.");
            localStorage.removeItem("userToken");
            setLoading(false);
            return;
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log("[INFO] ProfileDetails fetched successfully:", data);

        setProfile(data.userProfile);
        setLoading(false);
      } catch (err) {
        console.error("[ERROR] Failed to fetch profile:", err.message);
        setError(err.message || "Failed to fetch profile.");
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleEditToggle = () => {
    setEditable(!editable);
    if (!editable) setEditData(profile); // Load current profile data for editing
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
/*    
  // Ensure "N/A" or a valid number is accepted for specific fields
  const allowedFields = ["dob", "age", "overallExp"];
  if (allowedFields.includes(name)) {
    if (value !== "N/A" && isNaN(Number(value))) {
      console.warn(`${name} must be a valid number or "N/A".`);
      return;
    }
  }
*/
    setEditData({ ...editData, [name]: value });
  };

  const handleSave = async () => {
    try {
      // Validate and reformat `dob` field to DD/MM/YYYY
      if (editData.dob) {
        const dobParts = editData.dob.split('/');
        if (dobParts.length !== 3 || dobParts[0].length !== 2 || dobParts[1].length !== 2 || dobParts[2].length !== 4) {
          setError("Date of Birth must be in DD/MM/YYYY format.");
          return;
        }
      }
    
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("[ERROR] Backend error response:", errorResponse);
        throw new Error(errorResponse.message || "Failed to save changes.");
      }

      const data = await response.json();
      // console.log("[INFO] Profile updated successfully:", data);

        setProfile(data.personalParticulars);
        setEditable(false);
        fetchProfile();
      } catch (error) {
      console.error("[ERROR] Error updating profile:", error);
      setError(error.message || "Failed to save changes.");
    }
  };
  
  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <img
          src={Unisex}
          alt="Session Expired"
          className="w-16 h-16 mb-4"
        />
        <h1 className="text-xl font-bold text-gray-700 mb-2">Session Expired</h1>
        <p className="text-sm text-gray-500 mb-6">
          Your session has expired. Please log in again to continue.
        </p>
        <button
          onClick={() => navigate("/login")}
          className="px-4 py-2 bg-[#049c64] text-white rounded-md text-sm hover:bg-emerald-500 transition-colors"
        >
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <div>
      {/* User Profile Summary */}
      {/*<h1 className="text-xl font-bold text-gray-700">Profile Details</h1>*/}
      <div className="p-4 border border-[#049c64] rounded-lg mb-4 sm:p-6 sm:mb-6 sm:flex sm:items-center sm:justify-between">
        {/* First Part: Image and Name */}
        <div className="flex items-center gap-4 mb-4 sm:w-1/4 sm:mb-0">
          <img
            src={Unisex}
            alt="Profile"
            className="w-10 h-10 rounded-full sm:w-12 sm:h-12"
          />
          <h2 className="text-sm font-semibold sm:text-sm">
            {profile.firstName} {profile.lastName}
          </h2>
        </div>

        {/* Second Part: Overall Experience */}
        <div className="text-center sm:w-1/4">
          <span className="font-medium">Overall Experience</span>
            <p>{profile.overallExp || "N/A"} Years </p>
        </div>

        {/* Third Part: Last Login */}
        <div className="text-center sm:w-1/4">
          <span className="font-medium">Last Login</span>
          <p>
            {(() => {
              const [datePart, timePart] = profile.lastLoginTime.split("T");
              const [year, month, day] = datePart.split("-");
              const [hour, minute, second] = timePart.split(":");
              const formattedTime = `${day}/${month}/${year}, ${
                +hour % 12 || 12
              }:${minute}:${second.substring(0, 2)} ${+hour >= 12 ? "PM" : "AM"}`;    
              return formattedTime;
            })()}
          </p>
        </div>

        {/* Final Part: Edit Button */}

          {editable && (
            <button
              className="flex items-center gap-2 px-4 py-2 bg-[#049c64] text-white rounded-md"
              onClick={handleSave}
            >
              <img src={save} alt="Save" className="w-2 h-2" />
              Save
            </button>
          )}
          <button
            className="flex items-center gap-2 px-4 py-2 bg-[#049c64] text-white rounded-md"
            onClick={handleEditToggle}
          >
            <img src={pen} alt="Edit" className="w-2 h-2" />
            {editable ? "Cancel" : "Edit"}
          </button>
        
      </div>

      {/* Personal Information Section */}
      <div className="mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3">
        <div className="flex items-center justify-between">
          <h3 className="flex items-center gap-2 text-base font-medium sm:text-base">
            <Info className="text-gray-600 w-5 h-5" />
            <span className="text-gray-600">Personal Information</span>
          </h3>
{/*
          <div>
            <button 
              className="flex items-center gap-2 px-2 py-1 text-white text-xs bg-[#049c64] rounded-md"
              onClick={handleEditToggle}
              >
              <img src={pen} alt="Edit" className="w-2 h-2" />
                {editable ? "Cancel" : "Edit"}
            </button>
          </div>
*/}
        </div>

        <div className="py-4 rounded-lg relative sm:p-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-6">
          {[
            { label: "First Name", field: "firstName", editable: true },
            { label: "Last Name", field: "lastName", editable: true },
            { label: "Date of Birth (DD/MM/YYYY)", field: "dob", editable: true },
            { label: "Age", field: "age", editable: true },
            { label: "Email Address", field: "email", editable: false }, // Non-editable
            { label: "Phone Number", field: "mobile", editable: false }, // Non-editable
            { label: "Gender", field: "gender", editable: true },
          ].map(({ label, field, editable: isEditable }) => (
            <div key={field} className="relative group">
              <p className="text-xs text-gray-600 mb-1">{label}</p>
              {isEditable ? (
                field === "dob" ? (
                  // Custom input for dob with placeholder and onChange handler
                  <input
                    type="text"
                    name="dob"
                    value={editable ? editData.dob || "" : profile.dob || "N/A"}
                    onChange={(e) => {
                      // Ensure the value is correctly formatted
                      const value = e.target.value;
                      const isValid =
                        /^(\d{0,2})\/?(\d{0,2})\/?(\d{0,4})$/.test(value); // Allow partial entry
                      if (isValid || value === "") {
                        setEditData({ ...editData, dob: value });
                      }
                    }}
                    placeholder="DD/MM/YYYY"
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? "border-gray-300" : "border-transparent"
                    }`}
                  />
                ) : (
                  // Default input for other editable fields
                  <input
                    type="text"
                    name={field}
                    value={editable ? editData[field] || "" : profile[field] || "N/A"}
                    onChange={handleChange}
                    placeholder={`Enter ${label} or N/A`}
                    disabled={!editable}
                    className={`font-medium text-xs p-1 border ${
                      editable ? "border-gray-300" : "border-transparent"
                    }`}
                  />
                )
              ) : (
                // Display value for non-editable fields
                <p className="font-medium text-xs">
                  {profile[field] || "N/A"}
                  {(field === "email" || field === "mobile") && (
                    <span className="text-blue-500 ml-2 cursor-pointer relative group">
                      <AlertCircle className="inline w-4 h-4" />
                      {/* Tooltip */}
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 translate-y-[-8px] bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10">
                        Please contact us to update this information.
                      </span>
                    </span>
                  )}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      </div>

      {/* Location Information */}
      <div className="mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3">
        <div className="flex items-center justify-between">
          <h3 className="flex items-center gap-2 text-base font-medium sm:text-lg">
            <MapPin className="text-gray-600 w-5 h-5" />
            <span className="text-gray-600 text-base">
              Location Information
            </span>
          </h3>
{/*
          <div>
            <button 
              className="flex items-center gap-2 px-2 py-1 text-white text-xs bg-[#049c64] rounded-md"
              onClick={handleEditToggle}
            >
              <img src={pen} alt="Edit" className="w-2 h-2" />
                {editable ? "Cancel" : "Edit"}
            </button>
          </div>
*/}
          
        </div>

        <div className="p-4 relative sm:p-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6">
          {[
        { label: "Country", field: "country" },
        { label: "State", field: "state" },
        { label: "City", field: "city" },
        { label: "Street Address", field: "address" },
        { label: "Apartment", field: "apartment" },
        { label: "Zip Code", field: "zipCode" },
      ].map(({ label, field }) => (
        <div key={field}>
                <p className="text-xs text-gray-600 mb-1">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1")}
                </p>
                <input
                  type="text"
                  name={field}
                  value={editable ? editData[field] || "" : profile[field] || "N/A"}
                  onChange={handleChange}
                  placeholder={`Enter ${label} or N/A`}
                  disabled={!editable}
                  className={`font-medium text-xs p-1 border ${
                    editable ? "border-gray-300" : "border-transparent"
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Professional Experience */}
      <div className="mb-4 sm:mb-6 border border-[#049c64] rounded-md p-3">
        <div className="flex items-center justify-between">
          <h3 className="flex items-center gap-2 text-base font-medium sm:text-lg">
            <Award className="text-gray-600 w-5 h-5" />
            <span className="text-gray-600 text-base">
              Professional Experience
            </span>
          </h3>
        </div>

        <div className="p-4 relative sm:p-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6">
              {["overallExp",].map((field) => (
              <div key={field}>
                <p className="text-xs text-gray-600 mb-1">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1")}
                </p>
                <input
                  type="text"
                  name={field}
                  value={editable ? editData[field] || "" : profile[field] || "N/A"}
                  onChange={handleChange}
                  disabled={!editable}
                  placeholder="Enter Overall Experience or N/A"
                  className={`font-medium text-xs p-1 border ${
                    editable ? "border-gray-300" : "border-transparent"
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

{/*
      <div className=" border border-[#049c64] rounded-lg p-4 mb-[5%]">
        <div className="flex items-center justify-between mb-2 ">
          <h3 className="flex items-center gap-2 text-base font-medium sm:text-base">
            <img src={Payment} alt="Description" className="w-4 h-4 " />
            <span className="text-gray-600">Account Information</span>
          </h3>
          <div className="-mr-[1%]">
            <button className="flex items-center gap-2 px-2 py-1 text-white text-xs bg-[#049c64] rounded-md">
              <img src={pen} alt="Edit" className="w-2 h-2" />
              Edit
            </button>
          </div>
        </div>
        <div className="space-y-4 p-2">
          {/* Account 01 
          
          <div className="flex items-center">
            <div className="flex items-center gap-3 font-medium text-xs">
              <img src={visa} alt="Description" className="w-6 h-6 rounded" />
              <div className="flex flex-col">
                <span className="text-[#979797]">Account 01</span>
                <div className="flex items-center gap-2">
                  <span className="text-[#333333]">**** **** **** 1141</span>
                  <span className="text-[#333333]">12/26</span>
                </div>
              </div>
            </div>
            <div className="ml-auto lg:ml-[10%]">
              <button className="text-red-500 border border-red-500 p-1 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Account 02 *
          <div className="flex items-center">
            <div className="flex items-center gap-3 font-medium text-xs">
              <img src={visa} alt="Description" className="w-6 h-6 rounded" />
              <div className="flex flex-col">
                <span className="text-[#979797]">Account 02</span>
                <div className="flex items-center gap-2">
                  <span className="text-[#333333]">**** **** **** 1241</span>
                  <span className="text-[#333333]">12/26</span>
                </div>
              </div>
            </div>
            <div className="ml-auto lg:ml-[10%]">
              <button className="text-red-500 border border-red-500 p-1 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default ProfileDetails;