import React from "react";

const AIPS = () => {
  return (
    <div className="min-h-screen bg-[#fff9f7] text-gray-800">
      {/* Hero Section */}
      <section className="bg-emerald-100 py-16 flex items-center justify-center relative">
        <div className="container mx-auto px-6 absolute bottom-4 text-center">
          <h1 className="text-3xl font-bold text-green-600">
            AI Powered Search
          </h1>
        </div>
      </section>

      {/* Coming Soon Section */}
      <div className="min-h-screen bg-[#fff9f7] flex items-center justify-center">
        <div className="text-center">
        <div className="text-3xl md:text-4xl lg:text-5xl font-bold text-emerald-600">
          Research Underway!
        </div>
          <p className="text-gray-600 mt-4 text-base md:text-lg">
            We’re cooking up something amazing! Stay tuned for concrete updates on our AI-powered search experience by mid-2025.
          </p>
        {/* Animated Loader */}
        <div className="mt-8 flex items-center justify-center space-x-2">
            <div className="w-4 h-4 bg-emerald-500 rounded-full animate-bounce"></div>
            <div className="w-4 h-4 bg-emerald-500 rounded-full animate-bounce delay-150"></div>
            <div className="w-4 h-4 bg-emerald-500 rounded-full animate-bounce delay-300"></div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AIPS;